<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Заповнення файлу із тарифами (пенсійний фонд)
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                      v-model="inputFile"
                      placeholder="Оберіть файл заповнення тарифів"
                      label="Файл-запит у форматі *csv"
                      prepend-icon="mdi-paperclip"
                      show-size
                      hide-details
                      filled
                      :color="!inputFile ? 'red' : 'grey'"
                      accept=".csv"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <month-component label="Місяць" v-model="config.month" :block="true" :button_style="'height: 100%'"/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field hide-details filled
                                label="Місяців заборгованості"
                                v-model.number="config.debt_period"
                                v-integer
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept" :disabled="!documentReady()" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="test_pension">
                    Тест
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert";
import MonthComponent from "@/components/MonthComponent.vue";

export default {
  name: "FillSocialQuery",
  components: {
    MonthComponent
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth'
    })
  },
  data() {
    return {
      config: {
        month: null,
        debt_period: 3,
      },
      formValid: false,
      inputFile: null,
    }
  },
  methods: {
    documentReady() {
      return !!(this.inputFile) && !!(this.config.month) && !!(this.config.debt_period)
    },
    test_pension() {
      fillTablesAPI.pension_fund(
          {
            month: '2024-05-01'
          }
      )
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = response.headers['x-blob-file-name'] || 'tax_bills_outcome.zip'
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            err.response.data.text()
                .then(data => {
                  const json = JSON.parse(data)
                  this.$store.dispatch(ALERT_SHOW, {text: json.detail, color: 'error lighten-1'})
                })

          })
    },
    crud_operation() {
      if (this.documentReady()) {
        const formData = new FormData()
        formData.append('config', JSON.stringify(this.config))
        formData.append('file', this.inputFile)
        fillTablesAPI.import_social_query(formData)
            .then(response => {
              if (response.data.type === 'application/json') {
                this.$store.dispatch(ALERT_SHOW, {text: "Виникла помилка при завантажені документа...", color: 'error lighten-1'})
                return
              }
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `new.csv`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    }
  },
  created() {
    this.config.month = this.current_month
  }
}
</script>

<style scoped>

</style>