<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      :left="!dayType"
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          :depressed="depressed"
          :class="class_"
          :width="width === 0 ? 'auto' : width"
          :block="block"
          style="position: relative"
          :style="button_style"
      >
        <span style="margin-right: 10px" class="success--text text--darken-1 font-weight-bold" :style="label_style">{{ `${label}:` }}</span>
        {{ computedDateFormatted }}
      </v-btn>
    </template>
    <v-date-picker
        v-model="date"
        :type="dayType ? 'date' : 'month'"
        no-title
        scrollable
        :first-day-of-week="1"
        color="secondary"
        min="1910-01-01"
        locale="uk-UA"
    >
      <v-spacer/>
      <v-btn
          depressed text tile color="grey"
          @click="menu = false"
      >
        Відмінити
      </v-btn>
      <v-btn
          depressed text
          color="secondary darken-1"
          @click="onSave"
      >
        <v-icon small>mdi-calendar-check-outline</v-icon>
        ОК
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {formatDate} from "@/filters";

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    req: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: true
    },
    class_: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    },
    block: {
      type: Boolean,
      default: false
    },
    dayType: {
      type: Boolean,
      default: false
    },
    button_style: {
      type: String,
      default: ''
    },
    label_style: {
      type: String,
      default: ''
    }
  },
  name: "MonthComponent",
  data() {
    return {
      date: this.value,
      menu: false
    }
  },
  methods: {
    onSave() {
      this.$emit('input', this.date)
      this.$emit('onMonthChange', this.date)
      this.$refs.menu.save(this.date)
    },
    formatDateLocal (date) {
      if (!date) return null
      if (date === undefined) return null

      return formatDate(date, this.dayType ? 'DD.MM.YYYY' : 'MMMM YYYY')
    },
  },
  computed: {
    computedDateFormatted: {
      get() {
        return this.formatDateLocal(this.date)
      },
      set () {
        this.date = null
        return null
      }
    }
  },
  watch: {
    date(value) {
      if(value.length === 7) {
        value = value + '-01'
      }
      this.date = value
      // this.$emit('change', value)
    },
    value() {
      this.date = this.value
    }
  },
}
</script>

<style scoped lang="scss">
  .span-label {
    font-weight: bold;
    margin-right: 10px;
  }
</style>